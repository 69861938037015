<template>
  <section class="page-inicio">

    <div class="main-container">


        <div class="home-slider glider-contain">
            <!-- <a href="#" class="btn-gray fadeInDown animated">Iniciar sesión</a> -->

            <ul class="slides glider fadeInUp animated">
                <li><img src="@/assets/imgs/Slide-1.jpg"></li>
                <li><img src="@/assets/imgs/Slide-2.jpg"></li>
                <li><img src="@/assets/imgs/Slide-3.jpg"></li>
                <li><img src="@/assets/imgs/Slide-4.jpg"></li>
            </ul>

            <div id="dots"></div>

        </div>

        <div class="planes fadeIn animated">
            <h2 class="fadeInUp animated">¡Toma el control hoy mismo!</h2>

            <div class="main-container fadeInUp animated">
                <div class="plan">
                    <h1 class="titulo-plan lite">wise</h1>
                    <ul>
                        <li>1 usuario</li>
                        <li>Ventas por departamento</li>
                        <li>Catálogo de compras por categorías</li>
                        <li>Control de proveedores general</li>
                        <li>Control de nómina</li>
                        <li>Análisis de ventas vs compras</li>
                        <li>Indicadores de éxito</li>
                        <li>Reportes </li>
                    </ul>
                </div>

                <div class="plan">
                    <h1 class="titulo-plan pro">wise</h1>
                    <ul>
                        <li>Inventarios por categoría</li>
                        <li>CoGS diario y mensual</li>
                        <li>Catálogo de proveedores especializado</li>
                        <li>Estado de resultados</li>
                        <li>Costeo al día de platillos</li>
                        <li>Rotación de platillos</li>
                        <li>Reportes de cada área</li>
                        <li>Indicadores extras</li>
                        <li>Asesoría personalizada</li>
                    </ul>
                </div>
            </div>

            <div class="video">
                <iframe width="960" height="540" src="https://www.youtube-nocookie.com/embed/b-WGTd-rSYo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            
        </div>


        <div class="be-analitical text-center">

            <svg xmlns="http://www.w3.org/2000/svg" width="1287" height="119.043" viewBox="0 0 1287 119.043">
              <g id="be-analitical.svg" transform="translate(-500 -1719)">
                <rect id="Rectangle_1" data-name="Rectangle 1" width="1287" height="119" transform="translate(500 1719)" fill="none"/>
                <text id="Be_analytical_be_wise." data-name="Be analytical, be wise." transform="translate(849.47 1807.568)" fill="#777878" font-size="54"  font-weight="700" letter-spacing="0.1em"><tspan x="0" y="0">Be analytical, be wise.</tspan></text>
                <text id="Be_analytical_be_wise.-2" data-name="Be analytical, be wise." transform="translate(554.639 1815.043)" fill="#777878" font-size="102.704" font-weight="700" letter-spacing="0.1em" opacity="0.149"><tspan x="0" y="0">Be analytical, be wise.</tspan></text>
              </g>
            </svg>

        </div>

        <div class="info">
            <div>
                <br>
                <p>A lo largo de todo este tiempo hemos logrado optimizar los recursos de varios restaurantes de la región, el acceso fácil a datos útiles es nuestra principal propuesta de valor, conoce lo que necesitas saber de tu restaurante, en el momento que lo requieres saber para una correcta toma de decisiones. </p>

                <p>En esta época de altas y bajas en el mercado la accesibilidad, organización y análisis de datos de compras y ventas conforme al tiempo son aliados indispensables para todo dueño de negocios, es por eso que creamos wise con el objetivo de apoyarnos y elevar la rentabilidad de nuestros negocios.</p>
            </div>

            <div class="info-blog">

                <a href="#" class="btn-amarillo">Infórmate</a>

                <div class="info-blog-intro">
                    <h3 class="fecha">15 de mayo, 2019</h3>
                    <p>Aspectos básicos a considerar
                    en la apertura de un restaurante.</p>
                    <a href="#" class="leermas">Leer más</a>
                </div>

                <div class="info-blog-intro">
                    <h3 class="fecha">14 de mayo, 2019</h3>
                    <p>Conoce tu propuesta de valor.</p>
                    <a href="#" class="leermas">Leer más</a>
                </div>
            </div>

        </div>

        <div class="banner-bottom">
            <img src="@/assets/imgs/home-banner-bottom.jpg" width="100%" height="auto">
        </div>

    </div>

  </section>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import Glider from 'glider-js'
import 'glider-js/glider.css'

export default {
  name: 'home',
  // components: {
  //   HelloWorld
  // }

  mounted() {

    document.querySelector(".mobile-menu").classList.remove('mobile-active');
    document.querySelector(".main-navigation").classList.remove('is-active');


    new Glider(document.querySelector('.glider'), {
      slidesToShow: 1,
      dots: '#dots',
      draggable: false,
      slidesToScroll: 1,
      arrowsKeyboard: false,

      // arrows: {
      //   prev: '.glider-prev',
      //   next: '.glider-next'
      // }
    });
  }
};
</script>


<style lang="stylus">
.glider-dots
    position: absolute;
    bottom: 60px;
    left: 30px;
    z-index 10

    @media only screen and (max-width: 48em)
        position relative
        margin 2em auto
        left 0
        bottom 0

.glider-dot
    width 23px
    height 23px
    background #ECECEC
    opacity 1

.glider-dot:hover, .glider-dot:focus, .glider-dot.active
    background #747575

#be-analitical
    .cls-1 {
    font-size: 20.132px;
    }

    .cls-1, .cls-2 {
    fill: #777878;
    font-family: "DIN Alternate";
    font-weight: 700;
    }

    .cls-2 {
    font-size: 20px;
    opacity: 0.15;
    }
    
.video
    margin 5em auto 0 auto
    position relative
    
    iframe
        display block
        margin auto
        width 90%
        max-width 960px   
        


</style>